import React, {useContext} from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";
import App from "./App";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Nationality from "./pages/Nationality";
import Apply from "./pages/Apply";
import Questions from "./pages/Questions";
import Result from "./pages/Result";
import Sucess from "./pages/Sucess";
import Test from "./pages/Test";
import RegType from "./pages/RegType";


document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/apply" element={<Apply/>}></Route>
      <Route path="/questions/:page" element={<Questions/>}></Route>
      <Route path="/result" element={<Result/>}></Route>
      <Route path="/nationality" element={<Nationality/>}></Route>
      <Route path="/success" element ={<Sucess />}></Route>
      <Route path="/reg-type" element={<RegType/>}></Route>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);



import Flex from "@react-css/flex";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import PhilNavbar from "../phil-routes/PhilNavbar";
import classes from "./Result.module.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "../components/Preloader";

const Result = () => {
  const [results, setResults] = useState([]);
  const [majors, setMajors] = useState([]);
  const [errors, setErrors] = useState(false);
  const location = useLocation();
  const navigator = useNavigate();
  const [mainImageCover, setMainImageCover] = useState("");
  const [resultsTitle, setResultsTitle] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let resultsTitleList = [];


  // @TODO add request body and change request to POST
  async function fetchData() {
    let formData = new FormData();

    for (let item of location.state.answersList) {
      formData.append(`answers[${item.questionID}]`, item.answer);
    }

    const res = await fetch(`${process.env.REACT_APP_WS_BASE_URL}/GetResults`, {
      method: "POST",
      body: formData,
    });
    res
      .json()
      .then((res) => {
        setResults(Object.values(res.Pattern));
        setMainImageCover(Object.values(res.Pattern)[0].image);
        if (Object.values(res.Pattern).length > 1){
          resultsTitleList.push(Object.values(res.Pattern)[0].name);
          resultsTitleList.push(Object.values(res.Pattern)[1].name);
        }else{
          setResultsTitle(Object.values(res.Pattern)[0].name);
        }
        setMajors(res.Pattern[0].majors.concat(res.Pattern[1].majors));
        setIsLoading(false);
      })
      .catch((err) => {
        setErrors(true);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickNavigation = () => {
    navigator("/apply");
  };


  return (
    <>
      <PhilNavbar />
      {isLoading && <Preloader isLoading={isLoading} />}
      { !isLoading &&
        <>
      <section className={classes.resultTop}>
        <Flex>
          <Container>
            <Row>
              <Col className={classes.textCol} lg={6} md={6}>
                <div className={classes.textHolder}>
                  {/* { (results.length > 1) ? <h1>{`${results[0].name} و ${results[1].name}`}</h1> : <h1> {results[0].name} </h1> } */}
                  {results.map((item, i) => {
                    return (
                      <div>
                        <h4
                          key={
                            item.id +
                            parseInt(Math.random().toString(5).slice(0, 3))
                          }
                        >
                          {item.name}
                        </h4>
                        <p
                          key={
                            item.id +
                            parseInt(Math.random().toString(10).slice(6, 9))
                          }
                        >
                          {item.description}
                        </p>
                        <small
                          key={
                            item.id +
                            parseInt(Math.random().toString(15).slice(11, 14))
                          }
                        >
                          {item.sections}
                        </small>
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col className={classes.imageCol} lg={6} md={6}>
                <div className={classes.imageHolder}>
                  <img
                    className="img-fluid"
                    src={`${process.env.REACT_APP_WS_UPLOADS_URL}/${mainImageCover}`}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
            <hr />
          </Container>
        </Flex>
      </section>
      <section className={classes.majorsSection}>
        <Flex>
          <Container>
            <h1 className={classes.majorsTitle}>التخصص المناسب</h1>
            <Row>
              {majors.map((major, i) => {
                return (
                  <Col
                    key={major.id}
                    className={classes.majorCol}
                    lg={4}
                    md={4}
                    onClick={handleClickNavigation}
                  >
                    <div className={classes.majorContentHolder}>
                      <div className={classes.imgHolder}>
                        <h3>{major.name}</h3>
                        <img
                          src={`${process.env.REACT_APP_WS_UPLOADS_URL}/${major.image}`}
                          className="img-fluid"
                          alt="Thumb"
                        />
                      </div>
                      <div className={classes.descHolder}>
                        <p>
                          {major.description}{" "}
                          <a
                            href={
                              major.read_more_link ? major.read_more_link : "#"
                            }
                          >
                            اقرأ المزيد
                          </a>
                        </p>
                      </div>
                      <div className={classes.featuresHolder}>
                        <h4>إذا كنت تحب</h4>
                        <ul>
                          <li>{major.point_1}</li>
                          <li>{major.point_2}</li>
                          <li>{major.point_3}</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Flex>
      </section>
      <Footer />
      </>
  }
    </>
  );
};

export default Result;

import React, { useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import classes from "../FormComponent.moduls.scss";
import { useLocation, useNavigate } from "react-router-dom";
import accIcon from "../../assets/images/doc-accepted.svg";
import jsonUrl from "../../assets/external-json/postgraduate.json";
import axios from "axios";
import TextFormComponent from "../TextFormComponent";

const MasterForm = ({studentNationality}) => {

  const navigator = useNavigate();
  const [departments, setDepartments] = useState([]);
  const studentName = React.createRef();
  const tawjeehiCert = React.createRef();
  const nationality = React.createRef();
  const bachelorEvaluation = React.createRef();
  const department = React.createRef();
  const uniGPA = React.createRef();
  const mobile = React.createRef();
  const bachelorCountry = React.createRef();
  const studentWhatsApp = React.createRef();
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [addFile, setAddFile] = useState();

  const openFile = () => {
    tawjeehiCert.current.click();
  };

  useEffect(() => {
    setDepartments(jsonUrl.postgraduate);
  }, [departments]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("type","Master");
    formData.append("full_name",studentName.current.value);
    formData.append("nationality",nationality.current.value);
    formData.append("bachelor_source",bachelorCountry.current.value);
    formData.append("gpa",uniGPA.current.value);
    formData.append("major",department.current.value);
    formData.append("phone",mobile.current.value);
    formData.append("whatsapp",studentWhatsApp.current.value); // La 3youn ali
    formData.append("evaluation",bachelorEvaluation.current.value );
    formData.append("file[]", addFile);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_WS_BASE_URL}/Apply`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200){
        navigator("/success");
      }else{
        alert("حدث خطأ ما!")
      }
    } catch(error) {
      console.log(error)
    }
  };

  const handleFileChange = (e) => {
    setFileName("..." + tawjeehiCert.current.files[0].name.slice(0, 15));
    setAddFile(e.target.files[0]);
  };

  console.log(departments);


  return (
    // Masters form
    <>
      <Form
        method="POST"
        encType="multipart/form-data"
        className={classes.applyForm}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          <div className="inputHolder" data-value="1">
            <TextFormComponent label="الاسم الثلاثي" inputRef={studentName} />
          </div>

          <div data-value="2" className="inputHolder">
            <Form.Select
              required
              className="form-control"
              style={{ textAlign: "start", padding: 0 }}
              ref={nationality}
            >
              <option value="0">الجنسية</option>
              <option
                value="أردني"
                defaultValue ={studentNationality === "jordanian"}
              >
                أردني
              </option>
              <option value="أخرى" defaultValue={studentNationality === "foreigner"}>أخرى</option>
            </Form.Select>
          </div>

          <div data-value="3" className="inputHolder">
            <TextFormComponent label="مصدر شهادة البكالوريوس" inputRef={bachelorCountry} />
          </div>
          <div className="inputHolder">
            {fileName && <img src={accIcon} className="accDoc" />}
            <Form.Label
              htmlFor="bachelorDocument"
              className={fileName ? "Active" : ""}
              onClick={openFile}
            >
              صورة عن شهادة البكالوريوس
            </Form.Label>
            <Form.Control
              required
              type="file"
              style={{ display: "none" }}
              id="my-file"
              name="inputFile"
              ref={tawjeehiCert}
              onChange={handleFileChange}
            />
            <Form.Control
              type="text"
              onClick={openFile}
              id="bachelorDocument"
              disabled
              value={fileName}
            />
          </div>
          <div className="inputHolder" data-value="4">
            <TextFormComponent label="المعدل" inputRef={uniGPA} />
          </div>
          <div className="inputHolder" data-value="5">
          <TextFormComponent label="تقدير درجة البكالوريوس" inputRef={bachelorEvaluation} />
          </div>
          <div className="inputHolder" data-value="6">
            <Form.Select
              required
              className="form-control"
              style={{ textAlign: "start", padding: 0 }}
              ref={department}
            >
              <option value="">اختر التخصص</option>
              {departments.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="inputHolder" data-value="7">
          <TextFormComponent type="tel" label="رقم الهاتف" inputRef={mobile} />
          </div>
          <div className="inputHolder" data-value="8">
          <TextFormComponent type="tel" label="WhatsApp" inputRef={studentWhatsApp} />
          </div>
        </Form.Group>
        <Button type="submit" className="formBtnSubmit">
          تسجيل{" "}
        </Button>
      </Form>
    </>
  );
};

export default MasterForm;

import Flex from "@react-css/flex";
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import PhilNavbar from "../phil-routes/PhilNavbar";
import classes from "./Sucess.module.scss";
import Footer from "../components/Footer";
import studentsCover from "../assets/images/Horizantal-Student.png";
import sucessIcon from "../assets/images/approve.svg";
import { useMediaQuery } from "react-responsive";
import resCheeringStuden from "../assets/images/Horizantal-Student.png";

const Sucess = () => {

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };


  return (
    <>
    <Desktop>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <img src={studentsCover} alt="Cheering Students" />
                <div className={classes.formHodler}>
                  <div className={classes.formContent}>
                    <div className={classes.textHolder}>
                      <img src={sucessIcon} alt="Sucess" />
                      <div className={classes.textHolder}>
                        <h2>تم تقديم طلبك بنجاح</h2>
                        <p>سنتواصل معك خلال 24 ساعة</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>
      <Footer />
    </Desktop>


    <Tablet>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <img src={studentsCover} alt="Cheering Students" />
                <div className={classes.formHodler}>
                  <div className={classes.formContent}>
                    <div className={classes.textHolder}>
                      <img src={sucessIcon} alt="Sucess" />
                      <div className={classes.textHolder}>
                        <h2 className="text-center p-0">تم تقديم طلبك بنجاح</h2>
                        <p className="text-center p-0">سنتواصل معك خلال 24 ساعة</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>
      <Footer />
    </Tablet>

    <Mobile>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <img src={resCheeringStuden} alt="Cheering Students" />
                <div className={classes.formHodler}>
                  <div className={classes.formContent} style={{width:"325px"}}>
                    <div className={classes.textHolder}>
                      <img src={sucessIcon} alt="Sucess" />
                      <div className={classes.textHolder}>
                        <h2 className="text-center p-0" >تم تقديم طلبك بنجاح</h2>
                        <p className="text-center p-0" >سنتواصل معك خلال 24 ساعة</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>
      <Footer />
    </Mobile>

    </>
  );
};

export default Sucess;

import React from "react";
import classes from "./WelecomeComponent.module.scss";
import { Col, Row } from "react-bootstrap";

const WelcomeComponent = () => {
  return (
    <Row>
      <Col className={classes.textHolderCol}>
        <div className={classes.textHolder}>
          <h2>مرحباً بك</h2>
          <small>تجربة جامعية مميزة في الجامعة الخاصة الأولى في المملكة الأردنية الهاشمية</small>
        </div>
      </Col>
    </Row>
  );
};

export default WelcomeComponent;

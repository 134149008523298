import React from 'react';
import { Audio } from  'react-loader-spinner';


const Preloader = ({isLoading}) => {
  return (
    <>

    {isLoading && <Audio
    height = "80"
    width = "80"
    radius = "9"
    color = '#0E9FE6'
    ariaLabel = 'three-dots-loading'
    wrapperStyle= {{ justifyContent:"center", alignItems: "center",  height:"70vh", zIndex:9999}}
    wrapperClass
  />}

    </>
  )
}

export default Preloader
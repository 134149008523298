import React, { useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import classes from "../FormComponent.moduls.scss";
import { useLocation, useNavigate } from "react-router-dom";
import accIcon from "../../assets/images/doc-accepted.svg";
import jsonUrl from "../../assets/external-json/departments.json";
import axios from "axios";



const BridgingForm = ({studentNationality}) => {

  const navigator = useNavigate();
  const [departments, setDepartments] = useState([]);
  const studentName = useRef("");
  const tawjeehiCert = useRef("");
  const nationality = useRef("");
  const twajeehiStream = useRef("");
  const department = useRef("");
  const tawjeehiGPA = useRef("");
  const mobile = useRef("");
  const tawjeehiCountry = useRef("");
  const studentWhatsApp = useRef("");
  const previousCollegeName = useRef("");
  const diplomaMajor = useRef("");
  const shamelGPA = useRef("");
  const shameCert = useRef("");
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [addFile, setAddFile] = useState();
  const [addShamelFile, setAddShamelFile]=useState("");
  const [shamelFileName, setShamelFileName] = useState("");

  const openFile = () => {
    tawjeehiCert.current.click();
  };

  const openSahmelFile = () => {
    shameCert.current.click();
  }

  const handleOnchange = (text) => {
    setValue(text);
    if (text === "") {
      setIsActive(false);
    } else {
      setIsActive(true);
    }

  };

  useEffect(() => {
    setDepartments(jsonUrl.departments);
  }, [departments]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("type","Bridge");
    formData.append("full_name",studentName.current.value);
    formData.append("nationality",nationality.current.value);
    formData.append("highschool_source",tawjeehiCountry.current.value);
    formData.append("gpa",tawjeehiGPA.current.value);
    formData.append("major",department.current.value);
    formData.append("phone",mobile.current.value);
    formData.append("whatsapp",studentWhatsApp.current.value); // La 3youn ali
    formData.append("stream",twajeehiStream.current.value );
    formData.append("file[]", addFile);
    formData.append("file[]", addShamelFile);
    formData.append("previous_college_name", previousCollegeName);
    formData.append("diploma_major", diplomaMajor);
    formData.append("shamel_gpa",shamelGPA);


    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_WS_BASE_URL}/Apply`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200){
        navigator("/success");
      }else{
        alert("حدث خطأ ما!");
      }
    } catch(error) {
      alert("حدث خطأ ما!");
    }
  };

  const handleFileChange = (e) => {
    setFileName("..." + tawjeehiCert.current.files[0].name.slice(0, 15));
    setAddFile(e.target.files[0]);
  };

  const handleShamelFileChange = (e) => {
    console.log("Insude shamel");
    setShamelFileName("..." + shameCert.current.files[0].name.slice(0, 15));
    setAddShamelFile(e.target.files[0]);
  };

  console.log(departments);

  return (
    <>
    <Form method="POST"
      encType="multipart/form-data"
      className={classes.applyForm}
      onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <div className="inputHolder" data-value="1">
            <Form.Label
              htmlFor="fullName"
              className={
                (studentName.current.value !== "") ? "Active" : ""
              }
            >
              الاسم الثلاثي
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={studentName}
              id="#fullName"
              onChange={(e) => handleOnchange(e.target.value)}
            />
          </div>
          <div data-value="2" className="inputHolder">
            <Form.Select required
              className="form-control"
              style={{ textAlign: "start", padding:0}}
              ref={nationality}
            >
              <option disabled>الجنسية</option>
              <option value="أردني" selected={studentNationality==="jordanian"}>أردني</option>
              <option value="أخرى" selected={studentNationality === "foreigner"}>أخرى</option>
            </Form.Select>
          </div>
          <div data-value="3" className="inputHolder">
            <Form.Label
              htmlFor="jordan-country"
              className={
                tawjeehiCountry.current.value !== "" ? "Active" : ""
              }
            >
              مصدر شهادة الثانوية العامة الثانوية العامة
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={tawjeehiCountry}
              className={classes.customInput}
              id="jordan-country"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="department"
              className={
                twajeehiStream.current.value !== "" ? "Active" : ""
              }
            >
              فرع الثانوية العامة
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={twajeehiStream}
              className={classes.customInput}
              id="department"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            {fileName && <img src={accIcon} className="accDoc" />}
            <Form.Label
              htmlFor="tawjeehiDocument"
              className={fileName ? "Active" : ""}
              onClick={openFile}
            >
              صورة عن شهادة الثانوية العامة
            </Form.Label>
            <Form.Control
              required
              type="file"
              style={{ display: "none" }}
              id="my-file"
              name="inputFile"
              ref={tawjeehiCert}
              onChange={handleFileChange}
            />
            <Form.Control
              type="text"
              onClick={openFile}
              id="tawjeehiDocument"
              disabled
              value={fileName}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="previous-collage"
              className={
                previousCollegeName.current.value !== "" ? "Active" : ""
              }
            >
              اسم الكلية المتوسطة
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={previousCollegeName}
              className={classes.customInput}
              id="previous-collage"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="diploma-major"
              className={
                diplomaMajor.current.value !== "" ? "Active" : ""
              }
            >
              اسم التخصص كما ورد في شهادة الدبلوم
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={diplomaMajor}
              className={classes.customInput}
              id="diploma-major"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="shamel-GPA"
              className={
                shamelGPA.current.value !== "" ? "Active" : ""
              }
            >
              معدل شهادة الشامل
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={shamelGPA}
              className={classes.customInput}
              id="shamel-GPA"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            {shamelFileName && <img src={accIcon} className="accDoc" />}
            <Form.Label
              htmlFor="shamelDoc"
              className={shamelFileName ? "Active" : ""}
              onClick={openSahmelFile}
            >
              ارفاق صورة عن شهادة الشامل
            </Form.Label>
            <Form.Control
              required
              type="file"
              style={{ display: "none" }}
              id="shamel-file"
              name="shamelInputFile"
              ref={shameCert}
              onChange={handleShamelFileChange}
            />
            <Form.Control
              type="text"
              onClick={openSahmelFile}
              id="shamelDoc"
              disabled
              value={shamelFileName}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="GPA"
              className={
                tawjeehiGPA.current.value !== "" ? "Active" : ""
              }
            >
              المعدل
            </Form.Label>
            <Form.Control
              required
              type="text"
              ref={tawjeehiGPA}
              className={classes.customInput}
              id="GPA"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            <Form.Select required
              className="form-control"
              style={{ textAlign: "start", padding:0}}
              ref={department}
            >
              <option value="0">اختر التخصص</option>
              {departments.map((department) => (
                <option key={department.id} value={department.name}>
                  {department.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="mobile"
              className={
                mobile.current.value !== "" ? "Active" : ""
              }
            >
              رقم الهاتف
            </Form.Label>
            <Form.Control
              required
              type="tel"
              ref={mobile}
              className={classes.customInput}
              id="mobile"
              onChange={handleOnchange}
            />
          </div>
          <div className="inputHolder">
            <Form.Label
              htmlFor="whatsapp"
              className={
              studentWhatsApp.current.value !== "" ? "Active" : ""
              }
            >
              WhatsApp
            </Form.Label>
            <Form.Control
              required
              type="tel"
              ref={studentWhatsApp}
              className={classes.customInput}
              id="whatsapp"
              onChange={handleOnchange}
            />
          </div>
        </Form.Group>
        <Button type="submit" className="formBtnSubmit">
          تسجيل{" "}
        </Button>
      </Form>
    </>
  )
}

export default BridgingForm
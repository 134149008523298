import Flex from "@react-css/flex";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
//import { useLocation } from "react-router-dom";
import classes from "./Apply.module.scss";
import PhilNavbar from "../phil-routes/PhilNavbar";
import studentsCover from "../assets/images/Horizantal-Student.png";
import Footer from "../components/Footer";
import { useMediaQuery } from "react-responsive";
import resCheerstudents from "../assets/images/Horizantal-Student.png";
import { useLocation } from "react-router-dom";
import BachelorForm from "../components/Forms/BachelorForm";
import MasterForm from "../components/Forms/MasterForm";
import BridgingForm from "../components/Forms/BridgingForm";

const Apply = (props) => {
  //  Add These when chosing nationality
  // const { state } = useLocation();
  //const { nationality } = state || {};

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const regData = useLocation();
const regType = regData.state.degType.degValue;
const nationality = regData.state.nationality;


console.log("test => ", regData.state.nationality);
console.log("Mydata  => ", regType);

  return (
    <>

     <Desktop>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <div className={classes.testCover}></div>
                {/* <img src={studentsCover} className={classes.coverImg} alt="Cheering Students" /> */}
                <div className={classes.formHodler}>
                  <div className={classes.formContent}>
                    <div className={classes.textHolder}>
                      <h2>طلب التحاق الكتروني</h2>
                      <p>!عبئ الطلب وسنتواصل معك خلال 24 ساعة</p>
                    </div>
                    <div className={classes.applyForm}>
                    {(regType === "bachelor") ? <BachelorForm nationality={nationality}  /> : (regType === "master") ? <MasterForm /> : (regType === "bridging") &&  <BridgingForm/> }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>

      <Footer />
    </Desktop>

    <Tablet>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <img src={studentsCover} alt="Cheering Students" />
                <div className={classes.formHodler}>
                  <div className={classes.formContentTablet}>
                    <div className={classes.textHolder}>
                      <h2>طلب التحاق الكتروني</h2>
                      <p>!عبئ الطلب وسنتواصل معك خلال 24 ساعة</p>
                    </div>
                    <div className={classes.applyForm}>
                    {(regType === "bachelor") ? <BachelorForm nationality={nationality}  /> : (regType === "master") ? <MasterForm /> : (regType === "bridging") &&  <BridgingForm/> }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>

      <Footer />
    </Tablet>

    <Mobile>
    <PhilNavbar />
      <Flex>
        <Container fluid>
          <Row>
            <Col lg={12} className={classes.coverColHolder}>
              <div className={classes.coverHolder}>
                <img src={resCheerstudents} alt="Cheering Students" />
                <div className={classes.resFormHodler}>
                  <div className={classes.resFormContent}>
                    <div className={classes.textHolder}>
                      <h2>طلب التحاق الكتروني</h2>
                      <p>!عبئ الطلب وسنتواصل معك خلال 24 ساعة</p>
                    </div>
                    <div className={classes.applyForm}>
                    {(regType === "bachelor") ? <BachelorForm nationality={nationality}  /> : (regType === "master") ? <MasterForm /> : (regType === "bridging") &&  <BridgingForm/> }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>

      <Footer />
    </Mobile>

    </>
  );
};

export default Apply;

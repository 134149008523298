import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Nationality.module.scss";
import Flex from "@react-css/flex";
import { Container, Row, Col, Button } from "react-bootstrap";
import PhilNavbar from "../phil-routes/PhilNavbar";
import jordanFlag from "../assets/images/jordan.svg";
import questionMark from "../assets/images/question-mark.svg";
import WelcomeComponent from "../components/WelcomeComponent";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Nationality = () => {
  let navigate = useNavigate();
  const [jordanianClicked, setJordanianClicked] = useState(false);
  const [foriegnClicked, setForeignClicked] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const handleNavigation = () => {
    jordanianClicked
      ? navigate("/reg-type", { state: { nationality: "jordanian" } })
      : navigate("/reg-type", { state: { nationality: "foreigner" } });
  };

  const handleClick = (e) => {
    let navigationType = e.currentTarget.getAttribute("data-value");
    if (navigationType === "jordanian") {
      setJordanianClicked(true);
      setForeignClicked(false);
      setDisabledBtn(false);
    } else {
      setJordanianClicked(false);
      setForeignClicked(true);
      setDisabledBtn(false);
    }
  };

  return (
    <>
      {/* Desktop */}
      <Desktop>
        <Container fluid>
          <Row>
            <Col lg={7} md={6}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      <div
                        className={
                          jordanianClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="jordanian"
                      >
                        <div className={classes.iconHolder}>
                          <img src={jordanFlag} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4>أردني</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل حاملين الرقم الوطني الأردني
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          foriegnClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="foreign"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> جنسيات أخرى</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الأجانب الراغبين بالتسجيل وغير الحاملين للرقم
                            الوطني الأردني
                          </p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.btnHolder}>
                      <Button
                        className={
                          disabledBtn ? classes.btnNavigate : classes.clickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Desktop>
      {/* Tablet */}
      <Tablet>
        <Container fluid>
          <Row>
            <Col lg={7} md={7}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      <div
                        className={
                          jordanianClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="jordanian"
                      >
                        <div className={classes.iconHolder}>
                          <img src={jordanFlag} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4>أردني</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل حاملين الرقم الوطني الأردني
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          foriegnClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="foreign"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> جنسيات أخرى</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الأجانب الراغبين بالتسجيل وغير الحاملين للرقم
                            الوطني الأردني
                          </p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.btnHolder}>
                      <Button
                        className={
                          disabledBtn ? classes.btnNavigate : classes.clickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={5} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Tablet>
      {/* Mobile */}
      <Mobile>
        <Container fluid>
          <Row>
            <Col lg={5} className={classes.coverImageHolder}>
              <PhilNavbar />
              <div className={classes.resCoverContentHolder}></div>
            </Col>
            <Col lg={7}>
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <Row>
                    <Col className={classes.restextHolderCol}>
                      <div className={classes.resTextHolder}>
                        <h2>مرحباً بك</h2>
                        <small>
                        تجربة جامعية مميزة في الجامعة الخاصة الأولى في المملكة الأردنية الهاشمية
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.resRegisterHolderCol}>
                      <div
                        className={
                          jordanianClicked
                            ? `${classes.clickedHelpHolder} ${classes.resHelpHolder}`
                            : classes.resHelpHolder
                        }
                        onClick={handleClick}
                        data-value="jordanian"
                      >
                        <div className={classes.resTextIconHolder}>
                          <div className={classes.iconHolder}>
                            <img src={jordanFlag} alt="َquestion mark icon" />
                          </div>
                          <div className={classes.HelpTitleolder}>
                            <h4>أردني</h4>
                          </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل حاملين الرقم الوطني الأردني
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          foriegnClicked
                            ? `${classes.clickedHelpHolder} ${classes.resHelpHolder}`
                            : classes.resHelpHolder
                        }
                        onClick={handleClick}
                        data-value="foreign"
                      >
                        <div className={classes.resTextIconHolder}>
                          <div className={classes.iconHolder}>
                            <img src={questionMark} alt="َquestion mark icon" />
                          </div>
                          <div className={classes.HelpTitleolder}>
                            <h4> جنسيات أخرى</h4>
                          </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            للطلاب الأجانب الراغبين بالتسجيل وغير الحاملين للرقم
                            الوطني الأردني
                          </p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.resBtnHolder}>
                      <Button
                        className={
                          disabledBtn ? classes.resBtnNavigate : classes.resClickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Mobile>
    </>
  );
};

export default Nationality;

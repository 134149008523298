import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import classes from "./LogoRoute.module.scss";
import Nav from "react-bootstrap/Nav";
import { useMediaQuery } from "react-responsive";

const LogoRoute = () => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  return (
    <>
      <Desktop>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Philadelphia" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          id="navbarScroll"
          className={classes.NavbarScroller}
          style={{ direction: "rtl" }}
        >
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link href="https://www.philadelphia.edu.jo/faculties">
              التخصصات
            </Nav.Link>

            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-01-24"
              className="pr-1"
            >
              الخصومات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-00-59"
              className="pr-1"
            >
              المتطلبات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-12-05-47-55"
              className="pr-1"
            >
              الرسوم
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Desktop>

      <Tablet>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Philadelphia" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          id="navbarScroll"
          className={classes.NavbarScroller}
          style={{ direction: "rtl" }}
        >
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/faculties"
              className="pr-1"
            >
              التخصصات
            </Nav.Link>

            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-01-24"
              className="pr-1"
            >
              الخصومات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-00-59"
              className="pr-1"
            >
              المتطلبات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-12-05-47-55"
              className="pr-1"
            >
              الرسوم
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Tablet>

      <Mobile>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Philadelphia" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          id="navbarScroll"
          className={classes.NavbarScroller}
          style={{ direction: "rtl" }}
        >
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/faculties"
              className="pr-1"
            >
              التخصصات
            </Nav.Link>

            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-01-24"
              className="pr-1"
            >
              الخصومات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-11-09-00-59"
              className="pr-1"
            >
              المتطلبات
            </Nav.Link>
            <Nav.Link
              href="https://www.philadelphia.edu.jo/ar/2013-09-12-05-47-55"
              className="pr-1"
            >
              الرسوم
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Mobile>
    </>
  );
};

export default LogoRoute;

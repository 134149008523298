import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import classes from "./PhilNavBar.module.scss";
import LogoRoute from "../components/LogoRoute";


const PhilNavbar = () => {
  return (

      <Navbar className={classes.navMain } expand="lg">
      <Container fluid>
        <LogoRoute/>
      </Container>
    </Navbar>

  );
};

export default PhilNavbar;

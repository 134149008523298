import React from "react";
import ContentAndCover from "../layouts/ContentAndCover";

const Index = () => {
  return (
    <>
    <ContentAndCover/>
    </>
  );
};

export default Index;

import React from 'react';
import classes from "./Footer.module.scss";
import { useMediaQuery } from 'react-responsive';

const Footer = () => {

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  return (
    <>
    <Desktop>
      <footer className={classes.mainFooter}>
    <div className={classes.contentHolder}>
      <div>
        <p>
          <a href="https://www.philadelphia.edu.jo/">تواصل معنا</a>
        </p>
      </div>
      <div>
        <p>جميع الحقوق محفوظة لجامعة فيلادلفيا 2022 &copy;</p>
      </div>
      <div>
        <p>
          تصميم و تطوير
          <a href="https://laithaljunaidy.com">Laith Al Junaidy</a>
        </p>
      </div>
    </div>
  </footer>
    </Desktop>


    <Tablet>
    <footer className={classes.mainFooter}>
    <div className={classes.contentHolder}>
      <div>
        <p>
          <a href="https://www.philadelphia.edu.jo/">تواصل معنا</a>
        </p>
      </div>
      <div>
        <p>جميع الحقوق محفوظة لجامعة فيلادلفيا 2022 &copy;</p>
      </div>
      <div>
        <p>
          تصميم و تطوير
          <a href="https://laithaljunaidy.com">Laith Al Junaidy</a>
        </p>
      </div>
    </div>
  </footer>
    </Tablet>

    <Mobile>
    <footer className={classes.mainFooter}>
    <div className={classes.resContentHolder}>
      <div>
        <p>
          <a href="https://www.philadelphia.edu.jo/">تواصل معنا</a>
        </p>
      </div>
      <div>
        <p>جميع الحقوق محفوظة لجامعة فيلادلفيا 2022&copy;</p>
      </div>
      <div>
        <p>
          تصميم و تطوير
          <a href="https://laithaljunaidy.com">Laith Al Junaidy</a>
        </p>
      </div>
    </div>
  </footer>
    </Mobile>
    </>

  )
}

export default Footer
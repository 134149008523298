import Flex from "@react-css/flex";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "../components/Footer";
import PhilNavbar from "../phil-routes/PhilNavbar";
import classes from "./Question.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Preloader from "../components/Preloader";



const Questions = () => {
  const navigator = useNavigate();
  let params = useParams();
  const [myQuestions, setMyquestions] = useState([]);
  const [pageNumber, setPageNumber] = useState(params.page);
  const [progressWidth, setProgressWidth] = useState();
  const [lastPage, setLastPage]  = useState();
  const [nextUrl, setNextUrl] = useState("");
  const [answersList, setAnswersList] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let data = [];
  const [isLoading, setIsLoading] = useState(true);


  async function fetchData() {
    const res = await fetch(
      `${process.env.REACT_APP_WS_BASE_URL}/GetQuestions?page=${pageNumber}`
    );
    res.json()
      .then((res) => {
          setMyquestions(res.Questions.data);
          setLastPage(res.Questions.last_page);
          if (res.Questions.next_page_url !== null){
            setPageNumber(parseInt(pageNumber) + 1);
          }else{
            setPageNumber(res.Questions.last_page);
          }
          setProgressWidth(Math.ceil(( pageNumber / res.Questions.total) * 1000));
          setNextUrl(res.Questions.next_page_url);
          setIsLoading(false);
          window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
      })
      .catch((err) => console.log(err));
  }

  // Fetching API
  useEffect(() => {
     fetchData();
  }, []);

  const handleNavigation = () => {
    const nonDuplicateList = answersList.filter((value, index, self) =>
      index === self.findLastIndex((t) => (
        t.questionID === value.questionID
      ))
    )
    console.log(nonDuplicateList);
    if (nextUrl !== null){
      let pageresultCount = (pageNumber - 1) * 10;
      console.log("pageNumber => ", pageNumber);
      console.log("pageResult => ", pageresultCount);
      console.log("length => ", nonDuplicateList.length)
      if ((nonDuplicateList.length) < pageresultCount){
        alert("الرجاء الاجابة عن جميع الاسئلة");
      }else{
        if (lastPage === (pageNumber - 1) && nonDuplicateList.length < pageresultCount){
          alert("الرجاء الاجابة عن جميع الاسئلة");
        }else{
          fetchData();
          navigator(`/questions/${pageNumber}`);
        }
      }
    }else{
      navigator('/result', {state: {"answersList":nonDuplicateList}});
    }

  };

  const handleonChange = (e) => {
    let questionId = e.currentTarget.getAttribute("data-question-id");
    data.push({
      "questionID": questionId,
      "answer": e.target.value
    });
    setAnswersList((currentObjects) => currentObjects.concat([...data]));
  };

  const mystyle= {
    width:`${progressWidth}%`,
    textAlign:"left"
  }

  return (
    <>
      <PhilNavbar/>
      <Preloader isLoading={isLoading}/>
      { !isLoading &&
      <>
      <Flex>
        <Container className={classes.questoinMain}>
          <div className={classes.textHolder}>
            <div className={classes.titleHolder}>
              <h2>اكتشف تخصصك</h2>
            </div>
            <div className={classes.descHolder}>
              <p>
              أسئلة بسيطة تساعدنا على تحديد التخصص الأنسب لشخصيتك وفق
                المعايير العالمية
              </p>
            </div>
          </div>
          <div className={classes.progressBar}>
            <div className={classes.progress} style={mystyle}></div>
          </div>
          <div className={classes.progressNumber} style={mystyle}>{progressWidth}%</div>
          <div className={classes.qauetionParent}>
            <div className={classes.questions}>
              <div className={classes.firstTitle}>
                <h3>إختر مدى تفضيلك لهذه الأمور</h3>
              </div>
              {myQuestions.map((question, index) => {
              return (
              <>
              <div  key={question.id} className={classes.questionTitleHolder}>
              <h3 key={question.id} >{question.text}</h3>
            </div>

            <div className={classes.answers2Holder}>
              <div className={classes.radioLabelHolder1}>
              <input key={`radio${question.id}_ans_1`}
                  type="radio"
                  name={`radio${question.id}`}
                  value="4"
                  id={`my_radio_button_id${question.id}_ans_1`}
                  data-question-id={question.id}
                  className={classes.bigCircle1}
                  onChange={handleonChange}
                />
                <label htmlFor={`my_radio_button_id${question.id}_ans_1`}>دائماً</label>
              </div>
              <div className={!isMobile ? classes.midRadioLabelHolder : classes.resMidRadioLabelHolder}>
              <input key={`radio${question.id}_ans_2`}
                  type="radio"
                  name={`radio${question.id}`}
                  value="3"
                  className={classes.midCircle}
                  id={`my_radio_button_id${question.id}_ans_2`}
                  data-question-id={question.id}
                  onChange={handleonChange}
                />
                <label htmlFor={`my_radio_button_id${question.id}_ans_2`}>غالباً</label>
              </div>
              <div className={!isMobile ? classes.smallRadioLabelHolder : classes.resMidRadioLabelHolder }>
              <input key={`radio${question.id}_ans_3`}
                  type="radio"
                  name={`radio${question.id }`}
                  value="2"
                  id={`my_radio_button_id${question.id}`}
                  className={classes.smallCircle}
                  data-question-id={question.id}
                  onChange={handleonChange}
                />
                <label htmlFor={`my_radio_button_id${question.id}_ans_3`}>أحياناً</label>
              </div>
              <div className={!isMobile ? classes.midRadioLabelHolder : classes.resMidRadioLabelHolder }>
              <input key={`radio${question.id}_ans_4`}
                  type="radio"
                  name={`radio${question.id}`}
                  value="1"
                  id={`my_radio_button_id${question.id}_ans_4`}
                  className={classes.midCircle}
                  data-question-id={question.id}
                  onChange={handleonChange}
                />
                <label htmlFor={`my_radio_button_id${question.id}_ans_4`}> نادراً</label>
              </div>
              <div className={!isMobile ? classes.radioLabelHolder : classes.resRadioLabelHolder}>
                <input key={`radio${question.id}_ans_5`}
                  type="radio"
                  name={`radio${question.id}`}
                  value="0"
                  id={`my_radio_button_id${question.id}_ans_5`}
                  className={classes.bigCircle2}
                  data-question-id={question.id}
                  onChange={handleonChange}
                />
                <label htmlFor={`my_radio_button_id${question.id}_ans_5`}>أبداً</label>
              </div>
            </div>
            </>
            )
          })}
              <Button
                className={classes.questionBtn}
                onClick={handleNavigation}
              >
                التالي
              </Button>
            </div>
          </div>
        </Container>
      </Flex>
      <Footer />
      </>
}
    </>

  );
};

export default Questions;

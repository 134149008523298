import React from "react";
import Flex from "@react-css/flex";
import { Container, Row, Col } from "react-bootstrap";
import PhilNavbar from "../phil-routes/PhilNavbar";
import studentsSide from "../assets/images/students.png";
import classes from "./ContentAndCover.module.scss";
import registerIcon from "../assets/images/register-icon.svg";
import questionMark from "../assets/images/question-mark.svg";
import { Link, useNavigate } from "react-router-dom";
import WelcomeComponent from "../components/WelcomeComponent";
import MediaQuery, { useMediaQuery } from "react-responsive";
import mobileStudent from "../assets/images/res-cheering-students.png";


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};


const ContentAndCover = () => {
  let navigator = useNavigate();
  const handleNavigate = (e) => {
    let navigationType = e.currentTarget.getAttribute("data-value");
    navigationType === "register"
      ? navigator("/nationality")
      : navigator("/questions/1");
  };

  return (
    <>
      <Desktop>
        <Container fluid>
          <Row>
            <Col lg={7} md={6}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      <div
                        className={classes.registerationHolder}
                        onClick={handleNavigate}
                        data-value="register"
                      >
                        <div className={classes.iconHolder}>
                          <img src={registerIcon} alt="Register Icon" />
                        </div>
                        <div className={classes.titleHolder}>
                          <h4>التسجيل</h4>
                        </div>
                        <div className={classes.textHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل في جامعة فيلادلفيا
                            للبكالوريوس، الماجستير او التجسير
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.helpHolder}
                        onClick={handleNavigate}
                        data-value="help"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> مساعدة باختيار التخصص</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            بإجابتك على عدد بسيط من الأسئلة يمكننا تحديد التخصص
                            الأنسب لك
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Desktop>

      <Tablet>
        <Container fluid>
          <Row>
            <Col lg={7} md={6}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      <div
                        className={classes.registerationHolder}
                        onClick={handleNavigate}
                        data-value="register"
                      >
                        <div className={classes.iconHolder}>
                          <img src={registerIcon} alt="Register Icon" />
                        </div>
                        <div className={classes.titleHolder}>
                          <h4>التسجيل</h4>
                        </div>
                        <div className={classes.textHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل في جامعة فيلادلفيا
                            للبكالوريوس، الماجستير او التجسير
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.helpHolder}
                        onClick={handleNavigate}
                        data-value="help"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> مساعدة باختيار التخصص</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            بإجابتك على عدد بسيط من الأسئلة يمكننا تحديد التخصص
                            الأنسب لك
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Tablet>

      <Mobile>
        <Container fluid>
          <Row>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <PhilNavbar />
              <div className={classes.resCoverContentHolder}></div>
            </Col>
            <Col lg={7} md={6}>
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <Row>
                    <Col className={classes.restextHolderCol}>
                      <div className={classes.resTextHolder}>
                        <h2>مرحباً بك</h2>
                        <small>
                          تجربة جامعية مميزة بالجامعة الخاصة الأولى بالمملكة
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.resRegisterHolderCol}>
                      <div
                        className={classes.resRegisterationHolder}
                        onClick={handleNavigate}
                        data-value="register"
                      >
                        <div className={classes.resTextIconHolder}>
                        <div className={classes.resIconHolder}>
                          <img src={registerIcon} alt="Register Icon" />
                        </div>
                        <div className={classes.titleHolder}>
                          <h4>التسجيل</h4>
                        </div>
                        </div>
                        <div className={classes.textHolder}>
                          <p>
                            للطلاب الراغبين بالتسجيل في جامعة فيلادلفيا
                            للبكالوريوس، الماجستير او التجسير
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.resHelpHolder}
                        onClick={handleNavigate}
                        data-value="help"
                      >
                        <div className={classes.resTextIconHolder}>
                        <div className={classes.resIconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" style={{paddingRight:"14px"}} />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4 style={{paddingRight:"14px"}}> مساعدة باختيار التخصص</h4>
                        </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>
                            بإجابتك على عدد بسيط من الأسئلة يمكننا تحديد التخصص
                            الأنسب لك
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Mobile>
    </>
  );
};

export default ContentAndCover;

import React, { useState } from 'react';
import classes from "./FormComponent.moduls.scss";
import Form from "react-bootstrap/Form";

const TextFormComponent =  React.forwardRef(({type='text', label, inputRef}) => {
    const [value, setValue] = useState('');

    const handleOnchange = (e) => {
        setValue(e.target.value);
      };

  return (
    <>
    <Form.Label
              htmlFor="bachelor-country"
              className={value && "Active"}
            >
              {label}
            </Form.Label>
            <Form.Control
              required
              value={value}
              type={type}
              ref={inputRef}
              className={classes.customInput}
              id="bachelor-country"
              onChange={handleOnchange}
            />
    </>
  )
})

export default TextFormComponent
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./RegType.module.scss";
import Flex from "@react-css/flex";
import { Container, Row, Col, Button } from "react-bootstrap";
import PhilNavbar from "../phil-routes/PhilNavbar";
import questionMark from "../assets/images/question-mark.svg";
import WelcomeComponent from "../components/WelcomeComponent";
import { useMediaQuery } from "react-responsive";

const RegType = () => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  let navigate = useNavigate();
  const [bachelorClicked, setBachelorClicked] = useState(false);
  const [masterClicked, setMasterClicked] = useState(false);
  const [bridgingClicked, setBridgingClicked] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [degValue, setDegValue] = useState();
  const nationality = useLocation();

  const handleNavigation = () => {
    navigate("/apply", {
      state: {
        degType: { degValue },
        nationality: nationality.state.nationality,
      },
    });
  };

  const handleClick = (e) => {
    let degreeType = e.currentTarget.getAttribute("data-value");
    console.log(degreeType);
    switch (degreeType) {
      case "bachelor": {
        updateBachelorValue(e);
        break;
      }
      case "master": {
        updateMasterValue(e);
        break;
      }
      case "bridging": {
        updateBridgingValue(e);
        break;
      }
      default: {
        console.log("hhh");
      }
    }
  };

  const updateBachelorValue = (e) => {
    setBachelorClicked(true);
    setMasterClicked(false);
    setBridgingClicked(false);
    setDisabledBtn(false);
    setDegValue(e.currentTarget.getAttribute("data-value"));
  };

  const updateMasterValue = (e) => {
    setMasterClicked(true);
    setBachelorClicked(false);
    setBridgingClicked(false);
    setDisabledBtn(false);
    setDegValue(e.currentTarget.getAttribute("data-value"));
  };

  const updateBridgingValue = (e) => {
    setBridgingClicked(true);
    setMasterClicked(false);
    setBachelorClicked(false);
    setDisabledBtn(false);
    setDegValue(e.currentTarget.getAttribute("data-value"));
  };

  return (
    <>
      {/* Desktop */}
      <Desktop>
        <Container fluid>
          <Row>
            <Col lg={7} md={6}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      {/* Start Bachelor */}
                      <div
                        className={
                          bachelorClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="bachelor"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> البكالوريوس</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Master */}
                      <div
                        className={
                          masterClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="master"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4>الماجستير</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Bridging */}
                      <div
                        className={
                          bridgingClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="bridging"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> التجسير</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.btnHolder}>
                      <Button
                        className={
                          disabledBtn ? classes.btnNavigate : classes.clickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Desktop>
      {/* Tablet */}
      <Tablet>
        <Container fluid>
          <Row>
            <Col lg={7} md={6}>
              <PhilNavbar />
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <WelcomeComponent />
                  <Row>
                    <Col className={classes.registerHolderCol}>
                      {/* Start Bachelor */}
                      <div
                        className={
                          bachelorClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="bachelor"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> البكالوريوس</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Master */}
                      <div
                        className={
                          masterClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="master"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4>الماجستير</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Bridging */}
                      <div
                        className={
                          bridgingClicked
                            ? `${classes.clickedHelpHolder} ${classes.helpHolder}`
                            : classes.helpHolder
                        }
                        onClick={handleClick}
                        data-value="bridging"
                      >
                        <div className={classes.iconHolder}>
                          <img src={questionMark} alt="َquestion mark icon" />
                        </div>
                        <div className={classes.HelpTitleolder}>
                          <h4> التجسير</h4>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.btnHolder}>
                      <Button
                        className={
                          disabledBtn ? classes.btnNavigate : classes.clickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
            <Col lg={5} md={6} className={classes.coverImageHolder}>
              <div className={classes.coverContentHolder}></div>
            </Col>
          </Row>
        </Container>
      </Tablet>
      {/* Mobile */}
      <Mobile>
        <Container fluid>
          <Row>
            <Col lg={5} className={classes.coverImageHolder}>
              <PhilNavbar />
              <div className={classes.resCoverContentHolder}></div>
            </Col>
            <Col lg={7}>
              <Flex className={classes.contentHolder}>
                <Container className={classes.contentContainer}>
                  <Row>
                    <Col className={classes.restextHolderCol}>
                      <div className={classes.resTextHolder}>
                        <h2>مرحباً بك</h2>
                        <small>
                          تجربة جامعية مميزة في الجامعة الخاصة الأولى في المملكة
                          الأردنية الهاشمية{" "}
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.resRegisterHolderCol}>
                      {/* Start Bachelor */}
                      <div
                        className={
                          bachelorClicked
                            ? `${classes.clickedHelpHolder} ${classes.resHelpHolder}`
                            : classes.resHelpHolder
                        }
                        onClick={handleClick}
                        data-value="bachelor"
                      >
                        <div className={classes.resTextIconHolder}>
                          <div className={classes.iconHolder}>
                            <img src={questionMark} alt="َquestion mark icon" />
                          </div>
                          <div className={classes.HelpTitleolder}>
                            <h4>البكالوريوس</h4>
                          </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Master */}
                      <div
                        className={
                          masterClicked
                            ? `${classes.clickedHelpHolder} ${classes.resHelpHolder}`
                            : classes.resHelpHolder
                        }
                        onClick={handleClick}
                        data-value="master"
                      >
                        <div className={classes.resTextIconHolder}>
                          <div className={classes.iconHolder}>
                            <img src={questionMark} alt="َquestion mark icon" />
                          </div>
                          <div className={classes.HelpTitleolder}>
                            <h4> الماجستير</h4>
                          </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب الراغبين بالتسجيل</p>
                        </div>
                      </div>
                      {/* Start Bridging */}
                      <div
                        className={
                          bridgingClicked
                            ? `${classes.clickedHelpHolder} ${classes.resHelpHolder}`
                            : classes.resHelpHolder
                        }
                        onClick={handleClick}
                        data-value="bridging"
                      >
                        <div className={classes.resTextIconHolder}>
                          <div className={classes.iconHolder}>
                            <img src={questionMark} alt="َquestion mark icon" />
                          </div>
                          <div className={classes.HelpTitleolder}>
                            <h4> التجسير</h4>
                          </div>
                        </div>
                        <div className={classes.HelpDescHolder}>
                          <p>للطلاب االراغبين بالتسجيل</p>
                        </div>
                      </div>
                    </Col>
                    <div className={classes.resBtnHolder}>
                      <Button
                        className={
                          disabledBtn
                            ? classes.resBtnNavigate
                            : classes.resClickedBtn
                        }
                        disabled={disabledBtn}
                        onClick={handleNavigation}
                      >
                        التالي
                      </Button>
                    </div>
                  </Row>
                </Container>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Mobile>
    </>
  );
};

export default RegType;
